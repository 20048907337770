import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  InboxOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  List,
  MenuProps,
  Modal,
  Popover,
  Row,
  Select,
  Steps,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Buttons from "../../components/Button/Button";
import Contrainer from "../../components/Contrainer/Contrainer";
import Text from "../../components/Text/Text";
import { categotyKciDatasource } from "../../datasource/categotyKciDatasource";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { kciDatasource } from "../../datasource/kciDatasource";
import {
  KCI_HISTORY_STATUS,
  KCI_STATUS_COLOR_MAPPING,
  KCI_STATUS_MAPPING,
} from "../../definitions/KciStatus";
import color from "../../resource/color";
import icon from "../../resource/icon";
import { useForm, useWatch } from "antd/lib/form/Form";
import ReactQuill, { Quill } from "react-quill";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";

const QuillContainer = styled.div`
  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0 !important;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 4px 4px !important;
  }
  .ql-editor {
    max-height: 100px;
    overflow: auto;
    background-color: #ffff;
  }
`;

const IndexKci: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [loading, setLoading] = useState<boolean>(true);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [selectPeriod, setSelectPeriod] = useState<string>("KCI");
  const yearList = [2024, 2025];
  const period = ["KCI ของฉัน", "Full Year", "Q1", "Q2", "Q3", "Q4"];
  const [rowIndex, setRowIndex] = useState<any>("");
  const [modalMove, setModalMove] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalCopy, setModalCopy] = useState<boolean>(false);

  const changeActual = useWatch("actual", form);
  const changeWeight = useWatch("weight", form);

  const targetList = [
    { label: "Full Year", color: "" },
    { label: "Q1", color: "" },
    { label: "Q2", color: "" },
    { label: "Q3", color: "" },
    { label: "Q4", color: "" },
  ];

  const status = [
    { labelTh: "ทั้งหมด", labelEn: "", color: "" },
    { labelTh: "อนุมัติ", labelEn: "APPROVE", color: "#26CC73" },
    { labelTh: "ไม่อนุมัติ", labelEn: "REJECT", color: "#EB5757" },
    { labelTh: "รออนุมัติ", labelEn: "WAIT_APPROVE", color: "#F7D564" },
    { labelTh: "แบบร่าง", labelEn: "DRAFT", color: "#B1B4B2" },
  ];

  const [searchEmp, setSearchEmp] = useState<any>(Number(profile.employeeId));
  const [selectYear, setSelectYear] = useState<number>(yearList[0]);
  const [category, setCategory] = useState<any>();
  const [editKci, setEditKci] = useState<any>();
  const [dataKci, setDataKci] = useState<any>();
  const [tab, setTab] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [oneKci, setOneKci] = useState<any>();
  const [kciHistory, setKciHistory] = useState<any>();
  const [moveTarget, setMoveTarget] = useState<string>();
  const [notiList, setNotiList] = useState<{
    count: number;
    data: any;
    summary: any;
  }>();
  const [openNoti, setOpenNoti] = useState<boolean>(false);
  const [searchTabNoti, setSearchTabNoti] = useState<any>(undefined);
  const [textEditor, setTextEditor] = useState<any>(undefined);
  const [commentList, setCommentList] = useState<any>();

  const getKciByEmpId = async () => {
    setLoading(true);
    setDataKci([]);
    let pushEmp: any[] = [];
    const findEmp = await EmployeeApproveDatasource.getEmployeeApprove(
      profile?.employeeId
    ).then((res) => {
      return res.responseData || [];
    });
    pushEmp.push(searchEmp);
    if (findEmp?.length > 0 && !selectPeriod?.includes("KCI")) {
      findEmp.forEach((x: any) => {
        pushEmp.push(Number(x.employeeId));
      });
    }
    let mapChecked: any = [];
    await kciDatasource
      .getKci({
        years: selectYear,
        employeeId: pushEmp,
        target: selectPeriod.includes("KCI") ? undefined : selectPeriod,
        kciStatus: tab ? tab : undefined,
        search,
      })
      .then(async (res) => {
        mapChecked = [];
        if (selectPeriod.includes("KCI")) {
          await period
            .filter((x: any) => !x.includes("KCI"))
            .forEach(async (y: any) => {
              const matchWithName = await res.data.filter((x: any) => {
                return x.target === y;
              });
              const currentPeriod = [
                {
                  key: "section",
                  name: y,
                },
                ...matchWithName,
                {
                  key: "total",
                  name: matchWithName
                    .map((x: any) => Number(x.weight))
                    .reduce((acc: any, curr: any) => {
                      return acc + curr;
                    }, 0),
                },
              ];
              mapChecked.push(...currentPeriod);
            });
        } else {
          const empKci = await res.data.filter(
            (x: any) => `${x.employeeId}` === `${profile?.employeeId}`
          );
          const approveKci = await res.data.filter(
            (x: any) => `${x.employeeId}` !== `${profile?.employeeId}`
          );
          if (tab?.includes("WAIT")) {
            let currentPeriod = [];
            if (approveKci.length > 0) {
              currentPeriod.push(
                {
                  key: "section",
                  name: "รอการอนุมัติ",
                },
                ...approveKci
              );
            }
            currentPeriod.push(
              {
                key: "section",
                name: "KCI ของฉัน",
              },
              ...empKci
            );

            mapChecked.push(...currentPeriod);
          } else if (tab?.includes("REJECT")) {
            let currentPeriod = [
              {
                key: "section",
                name: "KCI ของฉัน",
              },
              ...empKci,
            ];
            if (approveKci.length > 0) {
              currentPeriod.push(
                {
                  key: "section",
                  name: "ประวัติการไม่อนุมัติ",
                },
                ...approveKci
              );
            }
            mapChecked.push(...currentPeriod);
          } else if (tab?.includes("APPROVE")) {
            let currentPeriod = [
              {
                key: "section",
                name: "KCI ของฉัน",
              },
              ...empKci,
            ];
            if (approveKci.length > 0) {
              currentPeriod.push(
                {
                  key: "section",
                  name: "ประวัติการอนุมัติ",
                },
                ...approveKci
              );
            }
            mapChecked.push(...currentPeriod);
          } else if (tab?.includes("DRAFT")) {
            const myKci = [
              {
                key: "section",
                name: "KCI ของฉัน",
              },
              ...empKci,
            ];
            mapChecked.push(...myKci);
          } else {
            if (empKci.length > 0) {
              const myKci = [
                {
                  key: "section",
                  name: `${empKci[0]?.employee?.firstname} ${empKci[0]?.employee?.lastname}`,
                },
                ...empKci,
              ];
              mapChecked.push(...myKci);
            }
            const groupEmp = _.groupBy(approveKci, ({ employeeId }: any) => {
              return employeeId;
            });
            const dataEmp = Object.keys(groupEmp).map((key) => {
              return groupEmp[key];
            });
            dataEmp.forEach((x) => {
              const find = res.data.filter(
                (y: any) => `${y.employeeId}` === `${x[0]?.employeeId}`
              );
              const currentPeriod = [
                {
                  key: "section",
                  name: `${x[0]?.employee?.firstname} ${x[0]?.employee?.lastname}`,
                },
                ...find,
              ];
              mapChecked.push(...currentPeriod);
            });
          }
        }
        mapChecked = await mapChecked.map((x: any) => {
          return { ...x, isChecked: false };
        });
        await setDataKci(mapChecked);
        setLoading(false);
      });

    await kciDatasource
      .getKci({
        years: selectYear,
        employeeId: pushEmp,
      })
      .then((res) => {
        setEditKci(res.data);
      });
  };

  const getOneKci = async (kciId: string) => {
    await kciDatasource.getKciById(kciId).then((res) => {
      setOneKci(res.responseData);
      form.setFieldsValue({
        kciid: res.responseData.kciId,
        kciDescription: res.responseData.kciDescription,
        target: res.responseData.target,
        category: res.responseData.categoryId,
        weight: Number(res.responseData.weight) || 0,
        actual: Number(res.responseData.actual) || 0,
        success: Number(res.responseData.success) || 0,
      });
    });
    await kciDatasource.getCommentKciById(kciId).then((res) => {
      setCommentList(res);
    });
  };

  const getCategyKci = async () => {
    await categotyKciDatasource.getCategory().then((res) => {
      setCategory(res.responseData);
    });
  };

  const getNoti = async () => {
    await kciDatasource
      .getNotiList({
        employeeId: profile.employeeId,
        page: 1,
        take: 10,
        isRead: searchTabNoti,
      })
      .then((res) => {
        setNotiList(res);
      });
  };

  useEffect(() => {
    getKciByEmpId();
    getCategyKci();
    getNoti();
  }, [selectPeriod, tab, search, selectYear]);

  useEffect(() => {
    getNoti();
  }, [searchTabNoti]);

  useEffect(() => {
    let payload = form.getFieldsValue(true);
    payload.actual = changeActual;
    payload.weight = changeWeight;
    form.setFieldsValue(payload);
    form.setFieldValue(
      "success",
      (Number(changeWeight) * Number(changeActual)) / 100
    );
  }, [changeActual, changeWeight]);

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  };

  const handleCheckBox = (e: any, kciId: string) => {
    const checked = e.target.checked;
    let mapData = dataKci;
    mapData = mapData.map((x: any) => {
      const find = x.kciId === kciId;
      if (find) {
        return { ...x, isChecked: checked };
      } else {
        return { ...x };
      }
    });
    setDataKci(mapData);
  };

  const handleAllCheckBox = (e: any) => {
    const checked = e.target.checked || false;
    let mapData = dataKci;
    mapData = mapData.map((x: any) => {
      return { ...x, isChecked: checked };
    });
    setDataKci(mapData);
  };

  const selectOption = () => {
    let items: MenuProps["items"] = [
      {
        label: (
          <>
            <img src={icon.kciApprove} style={{ fontSize: "20px" }} />{" "}
            <Text fontWeight={700} fontSize={16}>
              ดำเนินการ
            </Text>
          </>
        ),
        key: "manager",
        children: [
          {
            key: "approve",
            label: (
              <div onClick={() => kciApprove("APPROVE")}>
                <CheckOutlined
                  style={{ color: color.success, fontSize: "20px" }}
                />{" "}
                <Text color="success" fontWeight={700} fontSize={16}>
                  อนุมัติ
                </Text>
              </div>
            ),
          },
          {
            key: "reject",
            label: (
              <div onClick={() => kciApprove("REJECT")}>
                <CloseOutlined
                  style={{ color: color.error, fontSize: "18px" }}
                />{" "}
                <Text color="error" fontWeight={700}>
                  ไม่อนุมัติ
                </Text>
              </div>
            ),
          },
        ],
      },
      {
        label: (
          <div
            onClick={() => {
              setOpenHistory(true);
              getKciHistory(dataKci?.filter((x: any) => x.isChecked)[0].kciId);
            }}
          >
            <img src={icon.kcihistory} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              ประวัติการทำรายการ
            </Text>
          </div>
        ),
        key: "history",
      },
      {
        label: (
          <div
            onClick={() => {
              setModalMove(true);
              getOneKci(dataKci?.filter((x: any) => x.isChecked)[0].kciId);
            }}
          >
            <img src={icon.kcimove} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              ย้ายกลุ่ม
            </Text>
          </div>
        ),
        key: "move",
      },
      {
        label: (
          <div onClick={() => setModalCopy(true)}>
            <img src={icon.kcicopy} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              คัดลอก
            </Text>
          </div>
        ),
        key: "copy",
      },
      {
        label: (
          <div onClick={() => setModalDelete(true)}>
            <DeleteOutlined style={{ fontSize: "20px", color: color.error }} />{" "}
            <Text color="error" fontWeight={700} fontSize={16}>
              ลบ
            </Text>
          </div>
        ),
        key: "delete",
      },
    ];
    const optionMap = dataKci.filter((x: any) => x.isChecked);
    if (optionMap.length > 1) {
      const checkIsApprove = optionMap.filter((y: any) => !y.isMyApprove);
      if (checkIsApprove.length > 0) {
        items = items.filter((a) => a?.key === "delete" || a?.key === "copy");
      } else {
        items = items.filter(
          (a) => a?.key === "manager" || a?.key === "delete"
        );
      }
    } else {
      const checkStatus = optionMap[0];
      if (
        checkStatus?.kciStatus?.includes("WAIT") &&
        checkStatus?.isMyApprove
      ) {
        items = items;
      } else {
        items = items.filter((x) => x?.key !== "manager");
      }
    }
    return (
      <>
        <Row justify={"start"} gutter={8}>
          <Col span={7}>
            <Dropdown menu={{ items }}>
              <Button
                style={{
                  border: "none",
                  backgroundColor: "#F3F4FB",
                  width: "100%",
                }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Text color="navy" level={6} fontWeight={700}>
                      เลือก 1 รายการ
                    </Text>
                  </Col>
                  <Col>
                    <DownOutlined color="navy" />
                  </Col>
                </Row>
              </Button>
            </Dropdown>
          </Col>
          <Col style={{ paddingTop: "8px" }}>
            <Text
              level={6}
              color="navy"
              style={{ cursor: "pointer" }}
              onClick={(e: any) => handleAllCheckBox(e)}
            >
              <u>ล้างการเลือก</u>
            </Text>
          </Col>
        </Row>
        <br />
      </>
    );
  };

  const getKciHistory = async (kciId: string) => {
    await kciDatasource.getKciLog(kciId).then((res) => {
      let groupTime: any = _.groupBy(res.responseData, ({ createdAt }: any) =>
        moment(createdAt).format("DD/MM/YYYY")
      );
      const dataHis = Object.keys(groupTime).map((key) => {
        return groupTime[key];
      });
      setKciHistory(dataHis);
    });
  };

  const copyKci = async () => {
    const dataCopy = dataKci.filter((x: any) => x.isChecked);
    const kciIds: any = [];
    dataCopy.forEach((e: any) => {
      kciIds.push(e.kciId);
    });
    await kciDatasource
      .copyKci({
        kciId: kciIds,
        employeeId: Number(profile?.employeeId),
        createBy: `${profile.firstname} ${profile.lastname}`,
      })
      .then((res) => {
        setModalCopy(false);
        if (res.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "คัดลอกสำเร็จ",
            //showConfirmButton: true,
            width: 360,
          });
          getKciByEmpId();
        }
      });
  };

  const moveKci = async () => {
    const dataMove = dataKci.find((x: any) => x.isChecked);
    await kciDatasource
      .moveKci({
        kciId: [dataMove.kciId],
        target: moveTarget,
        updateBy: `${profile.firstname} ${profile.lastname}`,
      })
      .then((res) => {
        if (res.success) {
          setModalMove(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "ย้ายกลุ่มสำเร็จ",
            //showConfirmButton: true,
            width: 360,
          });
          getKciByEmpId();
        }
      });
  };

  const deleteKci = async () => {
    const mapDelete = dataKci.filter((x: any) => x.isChecked);
    let payload: any = [];
    mapDelete.forEach((e: any) => {
      payload.push(e.kciId);
    });
    await kciDatasource.deleteKci(payload).then((res) => {
      if (res.success) {
        setModalDelete(false);
        setOpenEdit(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ลบสำเร็จ",
          width: 360,
        });
        getKciByEmpId();
      }
    });
  };

  const kciApprove = async (action: string) => {
    let payload: any = [];
    const mapDelete = dataKci.filter((x: any) => x.isChecked);
    if (mapDelete.length > 0) {
      mapDelete.forEach((e: any) => {
        payload.push(e.kciId);
      });
    } else {
      payload.push(oneKci.kciId);
    }
    await kciDatasource
      .approveKci({
        kciId: payload,
        kciStatus: action,
        updateBy: `${profile.firstname} ${profile.lastname}`,
      })
      .then((res) => {
        if (res.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `ยืนยันการ${
              action === "APPROVE" ? `อนุมัติ` : `ไม่อนุมัติ`
            } ${payload.length > 0 ? payload.length : ""} รายการสำเร็จ`,
            showConfirmButton: true,
            width: 360,
          }).then((value) => {
            if (value.isConfirmed) {
              setOpenEdit(false);
              getKciByEmpId();
            }
          });
        }
      });
  };

  const editKciByManager = async () => {
    let payload = form.getFieldsValue(true);
    let mapData = oneKci;
    mapData.kciDescription = payload.kciDescription;
    mapData.kciStatus = "WAIT_APPROVE";
    mapData.categoryId = payload.category;
    mapData.target = payload.target;
    mapData.actual = payload.actual;
    mapData.success = payload.success;
    await kciDatasource.updateKci([mapData]).then((res) => {
      if (res.success) {
        setOpenEdit(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "แก้ไข KCI สำเร็จ",
          width: 360,
        });
        getKciByEmpId();
      }
    });
  };

  const tabChildren = () => {
    return (
      <div
        style={{
          height:
            notiList?.data?.length === 0
              ? "150px"
              : notiList?.data?.length > 2
              ? "300px"
              : `${notiList?.data?.length * 120}px`,
          overflow: "scroll",
        }}
      >
        {notiList?.data.length > 0 ? (
          <List
            dataSource={notiList?.data}
            renderItem={(item: any, i) => (
              <List.Item key={item?.notificationId}>
                <List.Item.Meta
                  avatar={
                    <>
                      {!item.isRead && (
                        <ConfigProvider
                          theme={{
                            token: {
                              fontSize: 25,
                            },
                          }}
                        >
                          <Badge color={color.navy} />
                        </ConfigProvider>
                      )}{" "}
                      <Avatar src={item?.employee?.employeeImage} />
                    </>
                  }
                  title={
                    <>
                      <Text fontWeight={700} level={6}>
                        {item?.employee?.firstname} {item?.employee?.lastname}
                      </Text>{" "}
                      <Text level={6} fontWeight={400}>
                        {item?.action} หัวข้อ:{item?.kci?.kciDescription}
                      </Text>
                    </>
                  }
                  description={
                    <>
                      <Text level={6} color="gray">
                        {moment(item?.createDate).format("DD/MM/YYYY")} |{" "}
                        {moment(item?.createDate).format("HH:ss")} •{" "}
                        {item?.action.includes("KCI")
                          ? "รายงาน"
                          : item?.action.includes("commented")
                          ? "Comment"
                          : "แก้ไข"}
                      </Text>
                      <br />
                      <Button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#F4F4F6",
                        }}
                        size="small"
                        onClick={() => {
                          readNoti(item.notificationId);
                          setOpenNoti(false);
                          getOneKci(item?.kciId);
                          setOpenEdit(true);
                        }}
                      >
                        <Text level={6}>เปิดรายการ</Text>
                      </Button>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <>
            <Empty description={<Text level={6}>ไม่มีการแจ้งเตือน</Text>} />
          </>
        )}
      </div>
    );
  };

  const tabNoti: any = [
    {
      key: undefined,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          ทั้งหมด (
          {Number(notiList?.summary?.isRead) +
            Number(notiList?.summary?.unread) || 0}
          )
        </Text>
      ),
      children: tabChildren(),
    },
    {
      key: true,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          อ่านแล้ว ({Number(notiList?.summary?.isRead) || 0})
        </Text>
      ),
      children: tabChildren(),
    },
    {
      key: false,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          ยังไม่ได้อ่าน ({Number(notiList?.summary?.unread) || 0})
        </Text>
      ),
      children: tabChildren(),
    },
  ];

  const notiContent = () => {
    return (
      <div style={{ width: "550px" }}>
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text level={3} fontWeight={700}>
              แจ้งเตือน
            </Text>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearchTabNoti(undefined);
              setOpenNoti(false);
            }}
          >
            <CloseOutlined />
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={searchTabNoti}
          items={tabNoti}
          onChange={(key) => setSearchTabNoti(key)}
        />
      </div>
    );
  };

  const readNoti = async (notiId: string) => {
    await kciDatasource.readKciNoti(notiId).then((res) => res);
  };

  const saveComment = async (kciId: string) => {
    await kciDatasource
      .commentKci({
        kciId: kciId,
        employeeId: profile.employeeId,
        comment: textEditor,
      })
      .then((res) => {
        if (res.success) {
          setTextEditor(undefined);
          getOneKci(kciId);
        }
      });
  };

  const columns: any = [
    {
      title: dataKci?.length > 1 && (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#34B16E",
            },
          }}
        >
          <Checkbox
            onClick={(e) => handleAllCheckBox(e)}
            checked={dataKci.every((x: any) => x.isChecked)}
          />
        </ConfigProvider>
      ),
      align: "left",
      key: "section",
      width: "3%",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            colSpan: 2,
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "section" && (
                    <Text level={6} fontWeight={700} color="navy">
                      {row.name === "Full Year" ? `${row.name} 2024` : row.name}
                    </Text>
                  )}
                </>
              ) : (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#34B16E",
                    },
                  }}
                >
                  <Checkbox
                    onClick={(e) => handleCheckBox(e, value.kciId)}
                    checked={value.isChecked}
                  />
                </ConfigProvider>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <center>
          <Text level={6} fontWeight={700} color="navy">
            คำอธิบาย
          </Text>
        </center>
      ),
      dataIndex: "kciDescription",
      key: "kciDescription",
      align: "left",
      width: "50%",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <Row gutter={8}>
                  <Col span={1}>
                    <ConfigProvider
                      theme={{
                        token: {
                          fontSize: 25,
                        },
                      }}
                    >
                      <Badge color={KCI_STATUS_COLOR_MAPPING[row.kciStatus]} />
                    </ConfigProvider>
                  </Col>
                  <Col span={23} style={{ paddingTop: "10px" }}>
                    <Text level={6} fontWeight={500}>
                      {value}
                    </Text>{" "}
                    {rowIndex === index && (
                      <EditOutlined
                        style={{ color: color.gray, fontSize: "16px" }}
                        onClick={() => {
                          setOpenEdit(true);
                          getOneKci(row?.kciId);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Owner
        </Text>
      ),
      key: "owner",
      dataIndex: "owner",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <div style={{ cursor: "default" }}>
                  {row?.employee?.employeeImage ? (
                    <Tooltip
                      title={`${row?.employee?.firstname} ${row?.employee?.lastname}`}
                    >
                      <Avatar src={row?.employee?.employeeImage || ""} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`${row?.employee?.firstname} ${row?.employee?.lastname}`}
                    >
                      <Avatar>
                        {row?.employee?.firstname.substring(0, 1) || ""}
                        {row?.employee?.lastname.substring(0, 1) || ""}
                      </Avatar>
                    </Tooltip>
                  )}
                </div>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Actual (%){" "}
          <Tooltip title="ระบุเปอร์เซ็นต์ที่ผลลัพท์เกิดขึ้นจริง">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "actual",
      dataIndex: "actual",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {value} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Weighted Avg{" "}
          <Tooltip title="ค่าเฉลี่ยถ่วงน้ำหนัก = Importance x Actual (%)">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "success",
      dataIndex: "success",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {value} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          หมวดหมู่{" "}
          <Tooltip title="เลือกมิติในการวัดผลการดำเนินงานขององค์กร แบ่งออกเป็น 4 มิติ">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "category",
      dataIndex: "category",
      align: "center",
      onCell: (value: any, row: any, index: number) => {
        return {
          style: {
            backgroundColor:
              value.key === "section" ? "#E8E9F8" : value?.category?.colorCode,
          },
        };
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={5} fontWeight={500}>
                      รวม
                    </Text>
                  )}
                </>
              ) : (
                <Text level={6} fontWeight={500} color="white" key={index}>
                  {row?.category?.categoryName}
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Importance{" "}
          <Tooltip title="ระบุเปอร์เซ็นต์น้ำหนักความสำคัญของ KCI นั้นๆ">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "weight",
      dataIndex: "weight",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={6} fontWeight={500}>
                      {row.name} %
                    </Text>
                  )}
                </>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {value} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Contrainer>
        <Row justify="space-between" gutter={8}>
          <Col span={5}>
            <Select
              showSearch
              bordered={true}
              value={selectYear}
              options={yearList.map((x) => {
                return {
                  label: (
                    <Text color="black" fontWeight={700} fontSize={20}>
                      KCI {x}
                    </Text>
                  ),
                  value: x,
                  key: x,
                };
              })}
              onChange={(key) => setSelectYear(key)}
              virtual={false}
              style={{
                width: "95%",
                height: "120%",
                overflow: "scroll",
              }}
            />
          </Col>
          <Col style={{ paddingTop: "10px" }}>
            <Popover
              placement="leftTop"
              arrow={false}
              content={notiContent}
              trigger="click"
              open={openNoti}
            >
              <Badge
                size="small"
                count={Number(notiList?.summary?.unread) || 0}
              >
                <Button
                  style={{ backgroundColor: "#E9ECEF" }}
                  icon={<InboxOutlined style={{ fontSize: "20px" }} />}
                  onClick={() => {
                    setOpenNoti(true);
                    setSearchTabNoti(undefined);
                  }}
                />
              </Badge>
            </Popover>
          </Col>
        </Row>
        <Divider />
        <Row gutter={8}>
          <Col>
            <Buttons
              onClick={() =>
                navigate(
                  `/createKci/${selectYear}/${
                    editKci?.length > 0 ? `${profile?.employeeId}` : 0
                  }`
                )
              }
              text={
                editKci?.length > 0
                  ? "แก้ไขรายการของฉัน"
                  : "สร้างรายการใหม่ของฉัน"
              }
              icon={
                editKci?.length > 0 ? (
                  <EditOutlined style={{ color: "white", fontSize: "20px" }} />
                ) : (
                  <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
                )
              }
            />
          </Col>
          <Col span={6}>
            <Input
              style={{ fontFamily: "Sarabun", width: "100%" }}
              suffix={<SearchOutlined />}
              placeholder="ค้นหาคำอธิบาย"
              onPressEnter={(e: any) => {
                if (e) {
                  setSearch(e.target.value);
                } else {
                  setSearch(undefined);
                }
              }}
            />
          </Col>
          {!selectPeriod.includes("KCI") && (
            <>
              <Col>
                <Popover
                  placement="bottomLeft"
                  content={
                    <div style={{ width: "400px" }}>
                      <Text fontWeight={700}>บุคคล</Text>
                      <br />
                      <Text fontSize={14}>
                        โปรดเลือกบุคคลที่ต้องการทราบข้อมูล
                      </Text>
                    </div>
                  }
                  trigger="click"
                >
                  <Button
                    //onClick={() => setOpen(!open)}
                    style={{ backgroundColor: "#E9ECEF" }}
                    icon={
                      <img src={icon.userIcon} style={{ fontSize: "25px" }} />
                    }
                  >
                    <Text level={5} fontWeight={700}>
                      บุคคล
                    </Text>
                  </Button>
                </Popover>
              </Col>
              <Col>
                <Popover
                  placement="bottomLeft"
                  content={
                    <div style={{ width: "400px" }}>
                      <Text fontWeight={700}>บุคคล</Text>
                      <br />
                      <Text fontSize={14}>
                        โปรดเลือกบุคคลที่ต้องการทราบข้อมูล
                      </Text>
                    </div>
                  }
                  trigger="click"
                >
                  <Button
                    //onClick={() => setOpen(!open)}
                    style={{ backgroundColor: "#E9ECEF" }}
                    icon={
                      <img src={icon.filterIcon} style={{ fontSize: "25px" }} />
                    }
                  >
                    <Text level={5} fontWeight={700}>
                      ตัวกรอง
                    </Text>
                  </Button>
                </Popover>
              </Col>
            </>
          )}
        </Row>
        <br />
        {dataKci?.filter((x: any) => x.isChecked).length > 0 && (
          <>{selectOption()}</>
        )}
        <Row>
          {period.map((x) => (
            <Button
              type="text"
              style={{
                backgroundColor: x.includes(selectPeriod) ? "#F3F4FB" : "",
              }}
              onClick={() => {
                setSelectPeriod(x);
              }}
            >
              <Text
                underline={x.includes(selectPeriod)}
                level={6}
                fontWeight={x.includes(selectPeriod) ? 700 : 400}
                color={x.includes(selectPeriod) ? "navy" : "black"}
              >
                {x}
              </Text>
            </Button>
          ))}
        </Row>
        <br />
        <Tabs
          size="middle"
          type="card"
          onChange={(key) => setTab(key)}
          items={status.map((s: any, i: any) => {
            return {
              label: (
                <>
                  <Badge color={s.color} size="default" />{" "}
                  <Text level={6} color="navy" fontWeight={700}>
                    {s.labelTh}
                  </Text>
                </>
              ),
              key: s.labelEn,
              children: (
                <Table
                  loading={loading}
                  size={"small"}
                  columns={columns}
                  dataSource={dataKci || []}
                  onRow={(record, rowIndex) => {
                    return {
                      onMouseEnter: (event: any) => {
                        setRowIndex(rowIndex);
                      },
                    };
                  }}
                  rowKey="key"
                  rowClassName={(record: any) =>
                    record.key === "section" ? "section-row" : ""
                  }
                  pagination={false}
                />
              ),
            };
          })}
        />
        <br />
      </Contrainer>
      <Drawer
        width={800}
        closable={false}
        destroyOnClose
        title={
          <Row gutter={8} justify="space-between">
            <Col span={12}>
              <Tag color="default">
                <Badge color={KCI_STATUS_COLOR_MAPPING[oneKci?.kciStatus]} />{" "}
                <Text fontWeight={700}>
                  {KCI_STATUS_MAPPING[oneKci?.kciStatus]}
                </Text>
              </Tag>
            </Col>
            <Col>
              <Row gutter={8} justify="space-between">
                {!oneKci?.isMyApprove && (
                  <Col style={{ cursor: "pointer" }}>
                    <EditOutlined
                      onClick={() =>
                        navigate(
                          `/createKci/${selectYear}/${
                            editKci?.length > 0 ? `${profile?.employeeId}` : 0
                          }`
                        )
                      }
                    />
                  </Col>
                )}
                <Col style={{ cursor: "pointer" }}>
                  <CopyOutlined onClick={() => setModalCopy(true)} />
                </Col>
                <Col style={{ cursor: "pointer" }}>
                  <DeleteOutlined onClick={() => setModalDelete(true)} />
                </Col>
                <Col style={{ cursor: "pointer" }}>
                  <CloseOutlined
                    onClick={() => {
                      getNoti();
                      setOpenEdit(false);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
        placement="right"
        open={openEdit}
      >
        <Form form={form}>
          {oneKci?.isMyApprove &&
            oneKci?.kciStatus !== "APPROVE" &&
            oneKci?.kciStatus !== "REJECT" && (
              <>
                <Row justify={"space-between"} gutter={8}>
                  <Col span={12}>
                    <Button
                      style={{ width: "100%", backgroundColor: color.success }}
                      onClick={() => kciApprove("APPROVE")}
                    >
                      <CheckOutlined
                        style={{ color: color.white, fontSize: "18px" }}
                      />
                      <Text color="white" fontWeight={700}>
                        อนุมัติ
                      </Text>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      style={{ width: "100%", backgroundColor: color.error }}
                      onClick={() => kciApprove("REJECT")}
                    >
                      <CloseOutlined
                        style={{ color: color.white, fontSize: "18px" }}
                      />
                      <Text color="white" fontWeight={700}>
                        ไม่อนุมัติ
                      </Text>
                    </Button>
                  </Col>
                </Row>
                <br />
              </>
            )}
          <Row>
            {(oneKci?.isMyApprove ||
              `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
            oneKci?.kciStatus !== "WAIT_APPROVE" ? (
              <Form.Item name="kciDescription" noStyle>
                <Input size="large" autoComplete="off" />
              </Form.Item>
            ) : (
              <Text fontWeight={700} level={4}>
                {oneKci?.kciDescription}
              </Text>
            )}
          </Row>
          <br />
          <Row gutter={8}>
            {oneKci?.employee?.employeeImage ? (
              <Col>
                <Avatar src={oneKci?.employee?.employeeImage} />
              </Col>
            ) : (
              <Col>
                <Avatar>
                  {oneKci?.employee?.firstname.substring(0, 1) || ""}
                  {oneKci?.employee?.lastname.substring(0, 1) || ""}
                </Avatar>
              </Col>
            )}
            <Col>
              <Text fontWeight={600}>
                {oneKci?.employee?.firstname} {oneKci?.employee?.lastname}
              </Text>
              <br />
              <Text color="gray" fontSize={12}>
                {oneKci?.employee?.positionName}
              </Text>
            </Col>
          </Row>
          <br />
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>กลุ่ม</Text>
            </Col>
            <Col span={8}>
              {(oneKci?.isMyApprove ||
                `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
              oneKci?.kciStatus !== "WAIT_APPROVE" ? (
                <Form.Item name="target" noStyle>
                  <Select
                    options={targetList?.map((c: any) => ({
                      key: c.label,
                      label: c.label,
                      value: c.label,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : (
                <Text fontWeight={400}>
                  {oneKci?.target.includes("Full")
                    ? `${oneKci?.target} ${oneKci?.years}`
                    : oneKci?.target}
                </Text>
              )}
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>หมวดหมู่</Text>
            </Col>
            <Col span={8}>
              {(oneKci?.isMyApprove ||
                `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
              oneKci?.kciStatus !== "WAIT_APPROVE" ? (
                <Form.Item name="category" noStyle>
                  <Select
                    options={category?.map((c: any) => ({
                      key: c.categoryId,
                      label: c.categoryName,
                      value: c.categoryId,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Tag
                    color={oneKci?.category?.colorCode}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Text fontWeight={700} color="white">
                      {oneKci?.category.categoryName}
                    </Text>
                  </Tag>
                </>
              )}
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Importance %</Text>
            </Col>
            <Col span={8}>
              <Form.Item name="weight" noStyle>
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={
                    (oneKci?.isMyApprove ||
                      `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
                    oneKci?.kciStatus !== "WAIT_APPROVE"
                      ? true
                      : false
                  }
                  disabled={
                    (oneKci?.isMyApprove ||
                      `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
                    oneKci?.kciStatus !== "WAIT_APPROVE"
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Actual %</Text>
            </Col>
            <Col span={8}>
              <Form.Item name="actual" noStyle>
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={
                    `${profile?.employeeId}` === `${oneKci?.employeeId}` &&
                    oneKci?.kciStatus === "APPROVE"
                      ? true
                      : false
                  }
                  disabled={
                    `${profile?.employeeId}` === `${oneKci?.employeeId}` &&
                    oneKci?.kciStatus === "APPROVE"
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Weighted Avg %</Text>
            </Col>
            <Col span={8}>
              <Form.Item name="success" noStyle>
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={false}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          {(oneKci?.isMyApprove ||
            `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
            oneKci.kciStatus !== "WAIT_APPROVE" && (
              <Row justify={"end"} gutter={8}>
                <Col span={3}>
                  <Button
                    style={{ width: "100%", height: "40px" }}
                    onClick={() => {
                      getNoti();
                      setOpenEdit(false);
                    }}
                  >
                    <Text fontWeight={700}>ยกเลิก</Text>
                  </Button>
                </Col>
                <Col span={3}>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: color.navy,
                      height: "40px",
                    }}
                    onClick={() => editKciByManager()}
                  >
                    <Text color="white" fontWeight={700}>
                      บันทึก
                    </Text>
                  </Button>
                </Col>
              </Row>
            )}
        </Form>
        <Divider />
        <Row>
          <Text level={6} fontWeight={500}>
            Comments ({commentList?.length})
          </Text>
        </Row>
        <br />
        {commentList?.length > 0 ? (
          <div
            style={{
              backgroundColor: "#F3F4FB",
              padding: "15px",
              borderRadius: "6px",
              height: "300px",
              overflow: "scroll",
            }}
          >
            {commentList?.map((c: any) => (
              <>
                <Row justify={"start"} gutter={8}>
                  <Col>
                    <Avatar src={c?.employee?.employeeImage} />
                  </Col>
                  <Col>
                    <Text level={6} fontWeight={700}>
                      {c?.employee?.firstname} {c?.employee?.lastname}
                    </Text>
                    <br />
                    <Text color="gray" fontSize={12}>
                      {c?.employee?.positionName}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} gutter={8}>
                  <Col span={20}></Col>
                  <Col span={4}>
                    <Text color="gray" fontSize={12}>
                      {moment(c?.creatDate).format("DD/MM/YYYY, HH:ss")}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"start"} gutter={8}>
                  <Col span={23}>
                    <div
                      style={{
                        width: "104%",
                        //height: "100%",
                        backgroundColor: "#ffff",
                        padding: "15px",
                        borderRadius: "8px",
                        display: "block",
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                        fontFamily: "IBM Plex Sans Thai",
                      }}
                      className="view ql-editor"
                      dangerouslySetInnerHTML={{ __html: c.comment }}
                    ></div>
                  </Col>
                </Row>
                <br />
              </>
            ))}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#ffff",
              padding: "15px",
              borderRadius: "6px",
              height: "150px",
            }}
          >
            <Empty description={<Text level={6}>ยังไม่มี Comment</Text>} />
          </div>
        )}
        <br />
        <div
          style={{
            backgroundColor: "#F3F4FB",
            padding: "15px",
            borderRadius: "6px",
          }}
        >
          <Row>
            <QuillContainer style={{ width: "100%" }}>
              <ReactQuill
                placeholder="กรอก Comment"
                //ref={quillRef}
                modules={modules}
                onChange={(c) => {
                  setTextEditor(c);
                }}
                value={textEditor}
                theme={"snow"}
              />
            </QuillContainer>
          </Row>
          <br />
          <Row justify={"end"}>
            <Col>
              <Button
                style={{ backgroundColor: color.navy }}
                onClick={() => saveComment(oneKci.kciId)}
              >
                <Text level={6} color="white" fontWeight={700}>
                  บันทึก
                </Text>
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      <Drawer
        width={600}
        closable={false}
        destroyOnClose
        title={
          <Row gutter={8} justify="space-between">
            <Col span={12}>
              <img src={icon.kcihistory} />{" "}
              <Text fontWeight={700} level={3}>
                ประวัติการทำรายการ
              </Text>
            </Col>
            <Col>
              <CloseOutlined onClick={() => setOpenHistory(false)} />
            </Col>
          </Row>
        }
        placement="right"
        open={openHistory}
      >
        <>
          {kciHistory?.map((x: any) => (
            <>
              <Text fontSize={16} fontWeight={700}>
                {moment(x[0].createdAt).format("DD/MM/YYYY")}
              </Text>
              <Steps
                direction="vertical"
                items={x.map((y: any) => {
                  return {
                    icon: (
                      <div style={{ paddingTop: "7px", color: color.navy }}>
                        {KCI_HISTORY_STATUS[y.action]}
                      </div>
                    ),
                    title: (
                      <Text color="navy" fontWeight={700}>
                        {moment(y.createdAt).format("HH:ss")}
                      </Text>
                    ),
                    subTitle: (
                      <Text color="navy" fontWeight={700}>
                        {y.action}
                      </Text>
                    ),
                    description: (
                      <>
                        {y.action === "แก้ไขรายการ" ||
                          (y.action === "หัวหน้าได้แก้ไขรายการ" && (
                            <Collapse
                              size="small"
                              items={[
                                {
                                  label: (
                                    <Text fontSize={14} fontWeight={400}>
                                      คุณได้แก้ไขและส่งคำขออนุมัติ KCI
                                    </Text>
                                  ),
                                  children: (
                                    <>
                                      {y.KCILogDetail.map((z: any) => {
                                        return (
                                          <Row>
                                            <Text fontSize={14}>
                                              <strong>• {z.action}</strong> "
                                              {z.beforeValue}" เป็น "
                                              {z.afterValue}"
                                            </Text>
                                          </Row>
                                        );
                                      })}
                                    </>
                                  ),
                                },
                              ]}
                            />
                          ))}
                        {y.action === "ย้ายกลุ่ม" && (
                          <Text fontSize={14} fontWeight={400}>
                            คุณได้ทำการย้ายกลุ่มเป้าหมาย{" "}
                            <strong>{y.beforeValue}</strong> เป็น{" "}
                            <strong>{y.afterValue}</strong>
                          </Text>
                        )}
                        {y.action === "คัดลอก" && (
                          <Text fontSize={14} fontWeight={400}>
                            คุณได้ทำการคัดลอก KCI
                          </Text>
                        )}
                        {y.action === "สร้างรายการใหม่" && (
                          <Text fontSize={14} fontWeight={400}>
                            คุณได้สร้างรายการ KCI ใหม่
                          </Text>
                        )}
                        {y.action === "หัวหน้าดำเนินการ" && (
                          <>
                            <Text fontSize={14} fontWeight={400}>
                              หัวหน้า
                              {y.kciStatus === "APPROVE"
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ"}
                            </Text>
                            <br />
                            <Tag color="default">
                              <Badge
                                color={KCI_STATUS_COLOR_MAPPING[y?.kciStatus]}
                              />{" "}
                              <Text fontSize={12} fontWeight={700}>
                                {KCI_STATUS_MAPPING[y?.kciStatus]}
                              </Text>
                            </Tag>
                          </>
                        )}
                      </>
                    ),
                    status: "finish",
                  };
                })}
              />
            </>
          ))}
        </>
      </Drawer>
      {modalMove && (
        <Modal
          title={
            <>
              <img src={icon.kcimove} />{" "}
              <Text fontWeight={700} level={2}>
                ย้ายกลุ่ม
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalMove}
          onCancel={() => setModalMove(!modalMove)}
          footer={() => (
            <>
              <Buttons text="ยกเลิก" bgColor={color.white} textColor="black" />
              <Buttons text="บันทึก" onClick={() => moveKci()} />
            </>
          )}
        >
          <Row>
            <Text fontWeight={700} level={4}>
              {oneKci?.kciDescription}
            </Text>
          </Row>
          <br />
          <Row justify={"space-between"}>
            <Col span={13}>
              <Text fontWeight={600}>เป้าหมายปัจจุบัน</Text>
            </Col>
            <Col span={11}>
              <Text fontWeight={600}>เป้าหมายใหม่</Text>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <Col span={11}>
              <Input
                value={oneKci?.target}
                disabled
                style={{ color: color.navy }}
              />
            </Col>
            <Col>
              <ArrowRightOutlined />
            </Col>
            <Col span={11}>
              <Select
                style={{ width: "100%" }}
                onChange={(key) => setMoveTarget(key)}
                options={
                  period
                    ?.filter((x) => !x.includes("KCI") && x !== oneKci?.target)
                    .map((y: any) => {
                      return {
                        label: (
                          <Text color="black" fontWeight={700}>
                            {y}
                          </Text>
                        ),
                        value: y,
                        key: y,
                      };
                    }) || []
                }
                placeholder="เลือกเป้าหมายใหม่"
              />
            </Col>
          </Row>
        </Modal>
      )}
      {modalDelete && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                ยืนยันการลบ KCI
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalDelete}
          onCancel={() => setModalDelete(!modalDelete)}
          footer={() => (
            <>
              <Buttons text="ยกเลิก" bgColor={color.white} textColor="black" />
              <Buttons
                text="ยืนยัน"
                onClick={() => deleteKci()}
                bgColor={color.error}
              />
            </>
          )}
        >
          <Text>
            โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน เพราะอาจส่งผล
            ต่อการแสดงผลข้อมูลในระบบ
          </Text>
        </Modal>
      )}
      {modalCopy && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                ยืนยันการคัดลอก KCI
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalCopy}
          onCancel={() => setModalCopy(!modalCopy)}
          footer={() => (
            <>
              <Buttons text="ยกเลิก" bgColor={color.white} textColor="black" />
              <Buttons
                text="ยืนยัน"
                onClick={() => copyKci()}
                bgColor={color.navy}
              />
            </>
          )}
        >
          <Text>โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน</Text>
        </Modal>
      )}
    </>
  );
};
export default IndexKci;

import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  LeftOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Switch,
  Divider,
  Tabs,
  Input,
  Badge,
  Button,
  ConfigProvider,
  Select,
  message,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Contrainer from "../../components/Contrainer/Contrainer";
import Text from "../../components/Text/Text";
import { categotyKciDatasource } from "../../datasource/categotyKciDatasource";
import { kciDatasource } from "../../datasource/kciDatasource";
import {
  KCI_EDIT_STATUS,
  KCI_STATUS_COLOR_MAPPING,
} from "../../definitions/KciStatus";
import color from "../../resource/color";

const CreateKci: React.FC = () => {
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;

  const empId = pathSplit[3] || 0;
  const year = pathSplit[2] || 0;

  const [search, setSearch] = useState<boolean>(false);
  const [tab, setTab] = useState<any>("Full Year");
  const [category, setCategory] = useState<any>();
  const [searchKciData, setSearchKciData] = useState<any>();
  const [deleteKci, setDeleteKci] = useState<any>([]);

  const status = [
    { label: "Full Year", color: "" },
    { label: "Q1", color: "" },
    { label: "Q2", color: "" },
    { label: "Q3", color: "" },
    { label: "Q4", color: "" },
  ];

  const [data, setData] = useState<any>(
    status.map((x) => ({
      kciDescription: "",
      categoryId: undefined,
      target: x.label,
      years: year,
      actual: 0,
      weight: 0,
      success: 0,
      employeeId: profile.employeeId,
      orderData: 1,
      kciStatus: "",
      isValiDes: false,
      isValiCate: false,
      isValiImpor: false,
    }))
  );

  const getKciByEmpId = async () => {
    await kciDatasource
      .getKci({
        years: year,
        employeeId: empId,
      })
      .then(async (res) => {
        let map: any = [];
        for (let i = 0; status.length > i; i++) {
          const find = res.data.filter(
            (x: any) => x.target === status[i].label
          );
          if (find.length > 0) {
            await find.forEach((e: any, j: number) => {
              map.push({
                kciId: e.kciId,
                kciDescription: e.kciDescription,
                categoryId: e.categoryId,
                target: e.target,
                years: e.years,
                actual: e.actual,
                weight: e.weight,
                success: e.success,
                employeeId: empId,
                orderData: j + 1,
                kciStatus: e.kciStatus,
                isValiDes: false,
                isValiCate: false,
                isValiImpor: false,
              });
            });
          } else {
            map.push({
              kciDescription: "",
              categoryId: undefined,
              target: status[i].label,
              years: year,
              actual: 0,
              weight: 0,
              success: 0,
              employeeId: profile.employeeId,
              orderData: 1,
              kciStatus: "",
              isValiDes: false,
              isValiCate: false,
              isValiImpor: false,
            });
          }
        }
        setData(map);
      });
  };

  const getCategyKci = async () => {
    await categotyKciDatasource.getCategory().then((res) => {
      setCategory(res.responseData);
    });
  };

  const searchKci = async () => {
    await kciDatasource
      .getKci({
        years: year,
        employeeId: empId,
      })
      .then(async (res) => {
        setSearchKciData(res.data);
      });
  };

  useEffect(() => {
    getCategyKci();
    if (empId > 0) {
      getKciByEmpId();
    }
  }, []);

  const pageTtile = () => {
    return (
      <>
        <Row gutter={8}>
          <Col>
            <Text
              level={6}
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              หน้าหลัก
            </Text>
          </Col>
          <Col>
            <Text level={6}>/</Text>
          </Col>
          <Col>
            <Text level={6}>
              {empId > 0 ? "แก้ไขรายการของฉัน" : "สร้างรายการใหม่ของฉัน"}{" "}
            </Text>
          </Col>
        </Row>
        <br />
        <Row gutter={8} justify={"space-between"}>
          <Col onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
            <LeftOutlined style={{ fontSize: "20px", paddingTop: "4px" }} />
          </Col>
          <Col span={19}>
            <Text level={3} fontWeight={700}>
              {empId > 0 ? "แก้ไขรายการของฉัน" : "สร้างรายการใหม่ของฉัน"}
            </Text>
          </Col>
          <Col span={4}>
            <Row gutter={8}>
              <Col style={{ paddingTop: "4px" }}>
                <Text level={5} fontWeight={600}>
                  เทียบ KCI กับบุคคลอื่น
                </Text>
              </Col>
              <Col>
                <div style={{ paddingTop: "4px" }}>
                  <Switch
                    style={{ backgroundColor: search ? "#00C875" : "#4A4B4F" }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={search}
                    onChange={() => {
                      setSearch(!search);
                      searchKci();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
      </>
    );
  };

  const addKci = () => {
    const checkTab = data.filter((x: any) => x.target === tab).length + 1 > 10;
    if (!checkTab) {
      setData([
        ...data,
        {
          kciDescription: "",
          categoryId: undefined,
          target: tab,
          years: year,
          actual: 0,
          weight: 0,
          success: 0,
          employeeId: profile.employeeId,
          orderData: data.filter((x: any) => x.target === tab).length + 1,
          kciStatus: "DARFT",
          isValiDes: false,
          isValiCate: false,
          isValiImpor: false,
        },
      ]);
    }
  };

  const onChangeValue = (e: any, t: string, name: string, i?: number) => {
    let payload = data;
    if (name === "des") {
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          return {
            ...x,
            kciDescription: e,
            isValiDes: false,
            kciStatus: "DRAFT",
          };
        } else {
          return { ...x };
        }
      });
    } else if (name === "category") {
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          return {
            ...x,
            categoryId: e,
            isValiCate: false,
            kciStatus: "DRAFT",
          };
        } else {
          return { ...x };
        }
      });
    } else if (name === "importance") {
      const reg = /^-?\d*(\.\d*)?$/;
      payload = payload.map((x: any) => {
        const map = x.orderData === i && x.target === t;
        if (map) {
          if (reg.test(e) || e === "" || e === "-") {
            return {
              ...x,
              weight: e,
              isValiImpor: false,
              kciStatus: "DRAFT",
            };
          } else {
            return {
              ...x,
            };
          }
        } else {
          return { ...x };
        }
      });
    }
    setData(payload);
  };

  const removeRow = async (t: string, i: number, kciId?: string) => {
    if (kciId) {
      let kciIdList: any = [];
      kciIdList.push(...deleteKci, kciId);
      setDeleteKci(kciIdList);
    }
    let payload = data;
    let row: number = 0;
    const filterData = payload
      .filter((x: any) => x.target !== t || x.orderData !== i + 1)
      .map((y: any) => {
        const find = y.target === t;
        if (find) {
          row = row + 1;
          return { ...y, orderData: row };
        } else {
          return { ...y };
        }
      });
    setData(filterData);
  };

  const save = async (action: string) => {
    let payload = data;
    if (action === "draft") {
      payload = payload.filter(
        (x: any) => x.categoryId || x.kciDescription || x.weight
      );
    }
    payload = payload.map((e: any, i: number) => {
      return {
        ...e,
        isValiDes: !e.kciDescription ? true : false,
        isValiCate: !e.categoryId ? true : false,
        isValiImpor: !e.weight ? true : false,
      };
    });
    setData(payload);
    const checkValidate = payload.filter(
      (x: any) => x.isValiCate || x.isValiDes || x.isValiImpor
    );
    if (checkValidate.length > 0) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "กรุณากรอกข้อมูลให้ครบ",
        showConfirmButton: true,
        width: 360,
      }).then((value) => {
        if (value.isConfirmed) {
          payload = payload.map((e: any, i: number) => {
            return {
              ...e,
              kciStatus: e.kciStatus,
            };
          });
          setData(payload);
        }
      });
    } else {
      payload = payload.map((e: any, i: number) => {
        if (e.kciId) {
          return {
            ...e,
            updateBy: `${profile.firstname} ${profile.lastname}`,
            kciStatus:
              action === "submit"
                ? e.kciStatus.includes("DRAFT") ||
                  e.kciStatus.includes("REJECT")
                  ? "WAIT_APPROVE"
                  : e.kciStatus
                : e.kciStatus,
          };
        } else {
          return {
            ...e,
            createBy: `${profile.firstname} ${profile.lastname}`,
            kciStatus:
              action === "submit"
                ? e.kciStatus.includes("DRAFT") ||
                  e.kciStatus.includes("REJECT")
                  ? "WAIT_APPROVE"
                  : e.kciStatus
                : e.kciStatus,
          };
        }
      });
      setData(payload);
      if (deleteKci.length > 0) {
        await kciDatasource.deleteKci(deleteKci).then((res) => {
          return res;
        });
      }
      if (empId > 0) {
        await kciDatasource.updateKci(payload).then((res) => {
          if (res.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                action === "draft"
                  ? "บันทึกแบบร่างสำเร็จ"
                  : "ยืนยันการส่งคำขออนุมัติสำเร็จ",
              showConfirmButton: true,
              width: 360,
            }).then((value) => {
              if (value.isConfirmed) {
                navigate(-1);
              }
            });
          }
        });
      } else {
        await kciDatasource.createKci(payload).then((res) => {
          if (res.success) {
            Swal.fire({
              position: "center",
              icon: "success",
              title:
                action === "draft"
                  ? "บันทึกแบบร่างสำเร็จ"
                  : "ยืนยันการส่งคำขออนุมัติสำเร็จ",
              showConfirmButton: true,
              width: 400,
            }).then((value) => {
              if (value.isConfirmed) {
                navigate(-1);
              }
            });
          }
        });
      }
    }
  };

  return (
    <Contrainer>
      {pageTtile()}
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              //cardBg: color.navy,
              //itemSelectedColor: color.navy,
            },
          },
        }}
      >
        <Tabs
          size="middle"
          defaultActiveKey={tab}
          type="card"
          onChange={(key) => {
            setTab(key);
          }}
          items={status.map((s: any, i: any) => {
            return {
              label: (
                <>
                  <Text
                    level={6}
                    color={tab === s.label ? "navy" : "black"}
                    fontWeight={700}
                  >
                    {s.label}
                  </Text>
                </>
              ),
              key: s.label,
              children: (
                <>
                  <div
                    style={{
                      backgroundColor: color.navy,
                      borderRadius: "8px",
                      height: "40px",
                      padding: "9px",
                    }}
                  >
                    <Row justify={"space-around"} gutter={8}>
                      <Col span={16}>
                        <Text color="white" fontWeight={700} fontSize={16}>
                          คำอธิบาย <span style={{ color: color.error }}>*</span>
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Text color="white" fontWeight={700} fontSize={16}>
                          หมวดหมู่ <span style={{ color: color.error }}>*</span>
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Text color="white" fontWeight={700} fontSize={16}>
                          Importance{" "}
                          <span style={{ color: color.error }}>*</span>
                        </Text>
                      </Col>
                    </Row>
                  </div>
                  <div
                    style={{
                      paddingTop: "8px",
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "#E8E9F8",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      height: "40px",
                      padding: "8px",
                    }}
                    key={s.label}
                  >
                    <Row justify={"space-around"} gutter={8}>
                      <Col span={20}>
                        <Text color="navy" fontSize={14}>
                          ทั้งหมด{" "}
                          {data.filter((x: any) => x.target === s.label).length}{" "}
                          / 10 รายการ
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Text color="navy" fontSize={14}>
                          รวม <b>Importance</b>{" "}
                          {data
                            .filter((a: any) => a.target === s.label)
                            .map((x: any) => {
                              return Number(x.weight);
                            })
                            .reduce((acc: any, curr: any) => {
                              return acc + curr;
                            }, 0)}{" "}
                          %
                        </Text>
                      </Col>
                    </Row>
                  </div>
                  <div
                    style={{
                      height:
                        data?.filter((a: any) => a.target === s.label).length <
                        5
                          ? `${data?.filter((a: any) => a.target === s.label)
                              .length} * 63px`
                          : "270px",
                      overflowY: "scroll",
                    }}
                  >
                    {data
                      ?.filter((a: any) => `${a.target}` === `${s.label}`)
                      ?.map((x: any, i: any) => (
                        <div
                          style={{
                            backgroundColor: "#F4F4F6",
                            height: "65px",
                            padding: "8px",
                          }}
                        >
                          <Row justify={"space-around"} gutter={8}>
                            <Col
                              span={1}
                              style={{
                                paddingTop: "6px",
                                paddingLeft: "20px",
                              }}
                            >
                              <Badge count={i + 1} color={color.navy} />
                            </Col>
                            <Col span={15}>
                              <Row>
                                {empId > 0 && x.kciStatus && (
                                  <Col span={1}>
                                    <ConfigProvider
                                      theme={{
                                        token: {
                                          fontSize: 25,
                                        },
                                      }}
                                    >
                                      <Badge
                                        color={
                                          KCI_STATUS_COLOR_MAPPING[x.kciStatus]
                                        }
                                      />
                                    </ConfigProvider>
                                  </Col>
                                )}
                                <Col span={empId > 0 && x.kciStatus ? 23 : 24}>
                                  <Input
                                    disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                    style={{
                                      backgroundColor: KCI_EDIT_STATUS[
                                        x.kciStatus
                                      ]
                                        ? color.lightgray
                                        : color.white,
                                      height: "40px",
                                      borderColor: color.error,
                                      color: color.black,
                                    }}
                                    placeholder="กรอกคำอธิบาย"
                                    bordered={x.isValiDes}
                                    value={x.kciDescription}
                                    autoComplete="off"
                                    onChange={(e: any) =>
                                      onChangeValue(
                                        e.target.value,
                                        s.label,
                                        "des",
                                        i + 1
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col span={4}>
                              <Select
                                placeholder="เลือกหมวดหมู่"
                                disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                style={{
                                  backgroundColor: KCI_EDIT_STATUS[x.kciStatus]
                                    ? color.lightgray
                                    : color.white,
                                  height: "40px",
                                  width: "100%",
                                  color: color.black,
                                }}
                                status={x.isValiCate ? "error" : ""}
                                value={x.categoryId}
                                bordered={x.isValiCate}
                                options={category?.map((c: any) => ({
                                  key: c.categoryId,
                                  label: c.categoryName,
                                  value: c.categoryId,
                                }))}
                                onChange={(e: any) =>
                                  onChangeValue(e, s.label, "category", i + 1)
                                }
                              />
                            </Col>
                            <Col span={3}>
                              <Input
                                disabled={KCI_EDIT_STATUS[x.kciStatus]}
                                style={{
                                  color: color.black,
                                  backgroundColor: KCI_EDIT_STATUS[x.kciStatus]
                                    ? color.lightgray
                                    : color.white,
                                  height: "40px",
                                  borderColor: color.error,
                                }}
                                suffix="%"
                                value={x.weight}
                                placeholder="Importance"
                                bordered={x.isValiImpor}
                                autoComplete="off"
                                onChange={(e: any) =>
                                  onChangeValue(
                                    e.target.value,
                                    s.label,
                                    "importance",
                                    i + 1
                                  )
                                }
                              />
                            </Col>
                            <Col span={1}>
                              {i > 0 &&
                                !KCI_EDIT_STATUS[x.kciStatus] &&
                                (empId ? (
                                  <DeleteOutlined
                                    style={{
                                      fontSize: "16px",
                                      paddingTop: "12px",
                                      cursor: "pointer",
                                      color: color.error,
                                    }}
                                    onClick={() =>
                                      removeRow(s.label, i, x.kciId)
                                    }
                                  />
                                ) : (
                                  <CloseOutlined
                                    style={{
                                      fontSize: "16px",
                                      paddingTop: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeRow(s.label, i)}
                                  />
                                ))}
                            </Col>
                          </Row>
                          <Row justify={"start"} gutter={8}>
                            <Col span={1}></Col>
                            {x.isValiDes && (
                              <>
                                <Col span={14}>
                                  <WarningFilled
                                    style={{ color: color.error }}
                                  />{" "}
                                  <Text color="error" level={6}>
                                    กรุณากรอกคำอธิบาย
                                  </Text>
                                </Col>
                              </>
                            )}
                            {x.isValiCate && (
                              <>
                                <Col span={4}>
                                  <WarningFilled
                                    style={{ color: color.error }}
                                  />{" "}
                                  <Text color="error" level={6}>
                                    กรุณาเลือกหมวดหมู่
                                  </Text>
                                </Col>
                              </>
                            )}
                            {x.isValiImpor && (
                              <Col span={4}>
                                <WarningFilled style={{ color: color.error }} />{" "}
                                <Text color="error" level={6}>
                                  กรุณากรอก Importance
                                </Text>
                              </Col>
                            )}
                          </Row>
                        </div>
                      ))}
                    <div
                      style={{
                        backgroundColor: "#F4F4F6",
                        height: "55px",
                        padding: "8px",
                      }}
                    >
                      <Button
                        type="dashed"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderColor: color.navy,
                          backgroundColor: "#F4F4F6",
                        }}
                        onClick={() => addKci()}
                      >
                        <PlusOutlined
                          style={{ fontSize: "20px", color: color.navy }}
                        />
                        <Text fontSize={20} fontWeight={700} color="navy">
                          เพิ่มรายการใหม่
                        </Text>
                      </Button>
                    </div>
                  </div>
                </>
              ),
            };
          })}
        />
      </ConfigProvider>
      <br />
      {search && (
        <div
          style={{
            border: "solid",
            borderRadius: "8px",
            borderColor: "#F3F4FB",
            padding: "15px",
          }}
        >
          <Row justify={"space-between"} gutter={8}>
            <Col span={12}>
              <Text level={4} fontWeight={700}>
                เทียบ KCI บุคคลอื่น
              </Text>
            </Col>
            <Col span={2}>
              <Select
                style={{
                  backgroundColor: color.white,
                  height: "40px",
                  width: "100%",
                }}
                placeholder="ค้นหาปี"
                bordered={true}
                options={[
                  {
                    key: 2024,
                    label: 2024,
                    value: 2024,
                  },
                ]}
                // onChange={(e: any) =>
                //   onChangeValue(e, s.label, "category", i + 1)
                // }
              />
            </Col>
            <Col span={6}>
              <Input
                style={{
                  backgroundColor: color.white,
                  height: "40px",
                }}
                suffix={<SearchOutlined />}
                placeholder="ค้นหาชื่อบุคคล/ตำแหน่ง"
                bordered
                autoComplete="off"
              />
            </Col>
            <Col span={4}>
              <Select
                style={{
                  backgroundColor: color.white,
                  height: "40px",
                  width: "100%",
                }}
                placeholder="ค้นหาหมวดหมู่"
                bordered={true}
                options={category?.map((c: any) => ({
                  key: c.categoryId,
                  label: c.categoryName,
                  value: c.categoryId,
                }))}
                // onChange={(e: any) =>
                //   onChangeValue(e, s.label, "category", i + 1)
                // }
              />
            </Col>
          </Row>
          <Tabs
            size="middle"
            defaultActiveKey="1"
            type="card"
            items={status.map((s: any, i: any) => {
              return {
                label: (
                  <>
                    <Text level={6} color="navy" fontWeight={700}>
                      {s.label}
                    </Text>
                  </>
                ),
                key: i,
                children: (
                  <>
                    <div
                      style={{
                        backgroundColor: color.navy,
                        borderTopRightRadius: "8px",
                        borderTopLeftRadius: "8px",
                        height: "40px",
                        padding: "9px",
                      }}
                    >
                      <Row justify={"space-around"} gutter={8}>
                        <Col span={16}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            คำอธิบาย
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            หมวดหมู่
                          </Text>
                        </Col>
                        <Col span={4}>
                          <Text color="white" fontWeight={700} fontSize={16}>
                            Importance
                          </Text>
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        height: "200px",
                        overflowY: "scroll",
                      }}
                    >
                      <div
                        style={{
                          height:
                            searchKciData?.filter(
                              (a: any) => a.target === s.label
                            ).length < 5
                              ? `${searchKciData?.filter(
                                  (a: any) => a.target === s.label
                                ).length} * 63px`
                              : "270px",
                          overflowY: "scroll",
                        }}
                      >
                        {searchKciData
                          ?.filter((a: any) => `${a.target}` === `${s.label}`)
                          ?.map((x: any, i: any) => (
                            <div
                              style={{
                                backgroundColor: "#F4F4F6",
                                height: "55px",
                                padding: "8px",
                              }}
                            >
                              <Row justify={"space-around"} gutter={8}>
                                <Col span={13}>
                                  <Text>
                                    {i + 1}. {x?.kciDescription}
                                  </Text>
                                </Col>
                                <Col span={5}>
                                  <Tag
                                    color={x?.category?.colorCode}
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    <Row justify={"center"}>
                                      <Text color="white">
                                        {x?.category?.categoryName}
                                      </Text>
                                    </Row>
                                  </Tag>
                                </Col>
                                <Col span={3}>
                                  <Text>{x?.weight} %</Text>
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ),
              };
            })}
          />
        </div>
      )}
      <br />
      <Row justify={"space-between"}>
        <Button
          style={{
            backgroundColor: "#F4F6F4",
            borderColor: "#F4F6F4",
            height: "40px",
          }}
          onClick={() => save("draft")}
        >
          <Text color="black" fontWeight={700}>
            บันทึกแบบร่าง
          </Text>
        </Button>
        <Button
          style={{ backgroundColor: color.navy, height: "40px" }}
          onClick={() => save("submit")}
        >
          <Text color="white" fontWeight={700}>
            บันทึก
          </Text>
        </Button>
      </Row>
      <br />
    </Contrainer>
  );
};

export default CreateKci;
